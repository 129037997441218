function CSVDataLookupCallback(target, isNewPosition) {
  const value = $(target).siblings(".csv-data-lookup").val();
  const fieldID = $(target).data("field-id");
  const $form = $(target).closest("form");
  const $approvers = $("#approvers ol");
  $(".csv-data-lookup-error").remove();
  $.get("/admin/system_fields/" + fieldID + "/remote", { position_id: value, new_position: isNewPosition }, function(response) {
    applyFields($form, response.fields);
    if (response.approvers !== void 0 || response.approvers.length !== 0) {
      applyApprovers($approvers, response.approvers);
    }
  }).fail(function(response) {
    $("div.csv-data-lookup-existing-position").after('<span class="csv-data-lookup-error">' + response.responseJSON.errors + "</span>");
  });
}
function clearFields($form) {
  $form.find("input[type=text][data-original-title]").val("");
}
function applyField($element, title, fields) {
  try {
    const value = fields[title];
    $element.find("input[type=text][data-original-title='" + title + "']").val(value);
    $element.find("select[data-original-title='" + title + "'] option:contains(" + value + ")").prop("selected", true);
  } catch (error) {
  }
}
function applyFields($element, collection) {
  collection.forEach(function(fields) {
    for (var title in fields) {
      applyField($element, title, fields);
    }
  });
}
function applyApprovers($element, collection) {
  if (collection.length > 0) {
    $element.html("");
  }
  collection.forEach(function(approver, i) {
    const name = "requisition[approvers_attributes][" + i + "][email]";
    const id = "requisition_approvers_attributes_email_" + i;
    const $div = $("<div></div>").addClass("approver");
    const $li = $("<li></li>").addClass("string input optional stringish");
    const $label = $("<label></label>").attr("for", id);
    const $input = $("<input></input>").attr({ id, type: "text", value: approver, name });
    $div.append($li.append($label).append($input));
    $element.append($div);
  });
}
function CSVDataLookypTypeCallback(target) {
  const $form = $(target).closest("form");
  const value = $(target).val();
  const $div1 = $("div.csv-data-lookup-existing-position");
  const $div2 = $("div.csv-data-lookup-new-position");
  const $input1 = $div1.find("input[type=text]");
  const $input2 = $div2.find("input[type=text]");
  if (value === "existing") {
    $div1.show();
    $input1.removeAttr("disabled");
    $div2.hide();
    $input2.attr("disabled", "disabled");
    clearFields($form);
  } else if (value === "new") {
    $div1.hide();
    $input1.attr("disabled", "disabled");
    $div2.show();
    $input2.removeAttr("disabled");
    clearFields($form);
  } else {
    $div1.hide();
    $input1.attr("disabled", "disabled");
    $div2.hide();
    $input2.attr("disabled", "disabled");
  }
}
$(document).ready(function() {
  if ($(".csv-data-lookup").length > 0) {
    $("select.csv-data-lookup-type").on("change", function(e) {
      CSVDataLookypTypeCallback(e.target);
    });
    $("input.csv-data-lookup-existing-position").on("click", function(e) {
      CSVDataLookupCallback(e.target, false);
    });
    $("input.csv-data-lookup-new-position").on("click", function(e) {
      CSVDataLookupCallback(e.target, true);
    });
    if ($(".csv-data-lookup").val().length > 0) {
      $(".csv-data-lookup-existing-position").trigger("click");
    }
  }
});
