$(document).ready(function() {
  const show_video = typeof show_vimeo_video != "undefined" ? show_vimeo_video : false;
  if (show_video && intro_video_url && interview_video_url) {
    var isMobile = $(window).width() < 600;
    var playThis = !isMobile ? intro_video_url : interview_video_url;
    var intro = videojs("intro", { "techOrder": ["vimeo"], "src": playThis }).ready(function() {
      this.on("ended", function() {
        $("#intro").css("display", "none");
        $("#interview").css("display", "block");
        if (!isMobile) {
          interview.play();
        }
      });
    });
    if (!isMobile) {
      var interview = videojs("interview", { "techOrder": ["vimeo"], "src": interview_video_url }).ready(function() {
        this.on("ended", function() {
          $("#interview").css("display", "none");
          $("#outro").css("display", "block");
          if (!isMobile) {
            outro.play();
          }
        });
      });
      var outro = videojs("outro", { "techOrder": ["vimeo"], "src": intro_video_url }).ready(function() {
        this.on("ended", function() {
          $("#outro").css("display", "none");
          $("#intro").css("display", "block");
        });
      });
    }
  }
});
