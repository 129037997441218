!function() {
  var a, AbstractChosen, Chosen, SelectParser, b, c = {}.hasOwnProperty, d = function(a2, b2) {
    function d2() {
      this.constructor = a2;
    }
    for (var e in b2) c.call(b2, e) && (a2[e] = b2[e]);
    return d2.prototype = b2.prototype, a2.prototype = new d2(), a2.__super__ = b2.prototype, a2;
  };
  SelectParser = function() {
    function SelectParser2() {
      this.options_index = 0, this.parsed = [];
    }
    return SelectParser2.prototype.add_node = function(a2) {
      return "OPTGROUP" === a2.nodeName.toUpperCase() ? this.add_group(a2) : this.add_option(a2);
    }, SelectParser2.prototype.add_group = function(a2) {
      var b2, c2, d2, e, f, g;
      for (b2 = this.parsed.length, this.parsed.push({ array_index: b2, group: true, label: this.escapeExpression(a2.label), children: 0, disabled: a2.disabled }), f = a2.childNodes, g = [], d2 = 0, e = f.length; e > d2; d2++) c2 = f[d2], g.push(this.add_option(c2, b2, a2.disabled));
      return g;
    }, SelectParser2.prototype.add_option = function(a2, b2, c2) {
      return "OPTION" === a2.nodeName.toUpperCase() ? ("" !== a2.text ? (null != b2 && (this.parsed[b2].children += 1), this.parsed.push({ array_index: this.parsed.length, options_index: this.options_index, value: a2.value, text: a2.text, html: a2.innerHTML, selected: a2.selected, disabled: c2 === true ? c2 : a2.disabled, group_array_index: b2, classes: a2.className, style: a2.style.cssText })) : this.parsed.push({ array_index: this.parsed.length, options_index: this.options_index, empty: true }), this.options_index += 1) : void 0;
    }, SelectParser2.prototype.escapeExpression = function(a2) {
      var b2, c2;
      return null == a2 || a2 === false ? "" : /[\&\<\>\"\'\`]/.test(a2) ? (b2 = { "<": "&lt;", ">": "&gt;", '"': "&quot;", "'": "&#x27;", "`": "&#x60;" }, c2 = /&(?!\w+;)|[\<\>\"\'\`]/g, a2.replace(c2, function(a3) {
        return b2[a3] || "&amp;";
      })) : a2;
    }, SelectParser2;
  }(), SelectParser.select_to_array = function(a2) {
    var b2, c2, d2, e, f;
    for (c2 = new SelectParser(), f = a2.childNodes, d2 = 0, e = f.length; e > d2; d2++) b2 = f[d2], c2.add_node(b2);
    return c2.parsed;
  }, AbstractChosen = function() {
    function AbstractChosen2(a2, b2) {
      this.form_field = a2, this.options = null != b2 ? b2 : {}, AbstractChosen2.browser_is_supported() && (this.is_multiple = this.form_field.multiple, this.set_default_text(), this.set_default_values(), this.setup(), this.set_up_html(), this.register_observers());
    }
    return AbstractChosen2.prototype.set_default_values = function() {
      var a2 = this;
      return this.click_test_action = function(b2) {
        return a2.test_active_click(b2);
      }, this.activate_action = function(b2) {
        return a2.activate_field(b2);
      }, this.active_field = false, this.mouse_on_container = false, this.results_showing = false, this.result_highlighted = null, this.allow_single_deselect = null != this.options.allow_single_deselect && null != this.form_field.options[0] && "" === this.form_field.options[0].text ? this.options.allow_single_deselect : false, this.disable_search_threshold = this.options.disable_search_threshold || 0, this.disable_search = this.options.disable_search || false, this.enable_split_word_search = null != this.options.enable_split_word_search ? this.options.enable_split_word_search : true, this.group_search = null != this.options.group_search ? this.options.group_search : true, this.search_contains = this.options.search_contains || false, this.single_backstroke_delete = null != this.options.single_backstroke_delete ? this.options.single_backstroke_delete : true, this.max_selected_options = this.options.max_selected_options || 1 / 0, this.inherit_select_classes = this.options.inherit_select_classes || false, this.display_selected_options = null != this.options.display_selected_options ? this.options.display_selected_options : true, this.display_disabled_options = null != this.options.display_disabled_options ? this.options.display_disabled_options : true;
    }, AbstractChosen2.prototype.set_default_text = function() {
      return this.default_text = this.form_field.getAttribute("data-placeholder") ? this.form_field.getAttribute("data-placeholder") : this.is_multiple ? this.options.placeholder_text_multiple || this.options.placeholder_text || AbstractChosen2.default_multiple_text : this.options.placeholder_text_single || this.options.placeholder_text || AbstractChosen2.default_single_text, this.results_none_found = this.form_field.getAttribute("data-no_results_text") || this.options.no_results_text || AbstractChosen2.default_no_result_text;
    }, AbstractChosen2.prototype.mouse_enter = function() {
      return this.mouse_on_container = true;
    }, AbstractChosen2.prototype.mouse_leave = function() {
      return this.mouse_on_container = false;
    }, AbstractChosen2.prototype.input_focus = function() {
      var a2 = this;
      if (this.is_multiple) {
        if (!this.active_field) return setTimeout(function() {
          return a2.container_mousedown();
        }, 50);
      } else if (!this.active_field) return this.activate_field();
    }, AbstractChosen2.prototype.input_blur = function() {
      var a2 = this;
      return this.mouse_on_container ? void 0 : (this.active_field = false, setTimeout(function() {
        return a2.blur_test();
      }, 100));
    }, AbstractChosen2.prototype.results_option_build = function(a2) {
      var b2, c2, d2, e, f;
      for (b2 = "", f = this.results_data, d2 = 0, e = f.length; e > d2; d2++) c2 = f[d2], b2 += c2.group ? this.result_add_group(c2) : this.result_add_option(c2), (null != a2 ? a2.first : void 0) && (c2.selected && this.is_multiple ? this.choice_build(c2) : c2.selected && !this.is_multiple && this.single_set_selected_text(c2.text));
      return b2;
    }, AbstractChosen2.prototype.result_add_option = function(a2) {
      var b2, c2;
      return a2.search_match ? this.include_option_in_results(a2) ? (b2 = [], a2.disabled || a2.selected && this.is_multiple || b2.push("active-result"), !a2.disabled || a2.selected && this.is_multiple || b2.push("disabled-result"), a2.selected && b2.push("result-selected"), null != a2.group_array_index && b2.push("group-option"), "" !== a2.classes && b2.push(a2.classes), c2 = document.createElement("li"), c2.className = b2.join(" "), c2.style.cssText = a2.style, c2.setAttribute("data-option-array-index", a2.array_index), c2.innerHTML = a2.search_text, this.outerHTML(c2)) : "" : "";
    }, AbstractChosen2.prototype.result_add_group = function(a2) {
      var b2;
      return a2.search_match || a2.group_match ? a2.active_options > 0 ? (b2 = document.createElement("li"), b2.className = "group-result", b2.innerHTML = a2.search_text, this.outerHTML(b2)) : "" : "";
    }, AbstractChosen2.prototype.results_update_field = function() {
      return this.set_default_text(), this.is_multiple || this.results_reset_cleanup(), this.result_clear_highlight(), this.results_build(), this.results_showing ? this.winnow_results() : void 0;
    }, AbstractChosen2.prototype.reset_single_select_options = function() {
      var a2, b2, c2, d2, e;
      for (d2 = this.results_data, e = [], b2 = 0, c2 = d2.length; c2 > b2; b2++) a2 = d2[b2], a2.selected ? e.push(a2.selected = false) : e.push(void 0);
      return e;
    }, AbstractChosen2.prototype.results_toggle = function() {
      return this.results_showing ? this.results_hide() : this.results_show();
    }, AbstractChosen2.prototype.results_search = function() {
      return this.results_showing ? this.winnow_results() : this.results_show();
    }, AbstractChosen2.prototype.winnow_results = function() {
      var a2, b2, c2, d2, e, f, g, h, i, j, k, l;
      for (this.no_results_clear(), d2 = 0, f = this.get_search_text(), a2 = f.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), i = new RegExp(a2, "i"), c2 = this.get_search_regex(a2), l = this.results_data, j = 0, k = l.length; k > j; j++) b2 = l[j], b2.search_match = false, e = null, this.include_option_in_results(b2) && (b2.group && (b2.group_match = false, b2.active_options = 0), null != b2.group_array_index && this.results_data[b2.group_array_index] && (e = this.results_data[b2.group_array_index], 0 === e.active_options && e.search_match && (d2 += 1), e.active_options += 1), (!b2.group || this.group_search) && (b2.search_text = b2.group ? b2.label : b2.text, b2.search_match = this.search_string_match(b2.search_text, c2), b2.search_match && !b2.group && (d2 += 1), b2.search_match ? (f.length && (g = b2.search_text.search(i), h = b2.search_text.substr(0, g + f.length) + "</em>" + b2.search_text.substr(g + f.length), b2.search_text = h.substr(0, g) + "<em>" + h.substr(g)), null != e && (e.group_match = true)) : null != b2.group_array_index && this.results_data[b2.group_array_index].search_match && (b2.search_match = true)));
      return this.result_clear_highlight(), 1 > d2 && f.length ? (this.update_results_content(""), this.no_results(f)) : (this.update_results_content(this.results_option_build()), this.winnow_results_set_highlight());
    }, AbstractChosen2.prototype.get_search_regex = function(a2) {
      var b2;
      return b2 = this.search_contains ? "" : "^", new RegExp(b2 + a2, "i");
    }, AbstractChosen2.prototype.search_string_match = function(a2, b2) {
      var c2, d2, e, f;
      if (b2.test(a2)) return true;
      if (this.enable_split_word_search && (a2.indexOf(" ") >= 0 || 0 === a2.indexOf("[")) && (d2 = a2.replace(/\[|\]/g, "").split(" "), d2.length)) {
        for (e = 0, f = d2.length; f > e; e++) if (c2 = d2[e], b2.test(c2)) return true;
      }
    }, AbstractChosen2.prototype.choices_count = function() {
      var a2, b2, c2, d2;
      if (null != this.selected_option_count) return this.selected_option_count;
      for (this.selected_option_count = 0, d2 = this.form_field.options, b2 = 0, c2 = d2.length; c2 > b2; b2++) a2 = d2[b2], a2.selected && (this.selected_option_count += 1);
      return this.selected_option_count;
    }, AbstractChosen2.prototype.choices_click = function(a2) {
      return a2.preventDefault(), this.results_showing || this.is_disabled ? void 0 : this.results_show();
    }, AbstractChosen2.prototype.keyup_checker = function(a2) {
      var b2, c2;
      switch (b2 = null != (c2 = a2.which) ? c2 : a2.keyCode, this.search_field_scale(), b2) {
        case 8:
          if (this.is_multiple && this.backstroke_length < 1 && this.choices_count() > 0) return this.keydown_backstroke();
          if (!this.pending_backstroke) return this.result_clear_highlight(), this.results_search();
          break;
        case 13:
          if (a2.preventDefault(), this.results_showing) return this.result_select(a2);
          break;
        case 27:
          return this.results_showing && this.results_hide(), true;
        case 9:
        case 38:
        case 40:
        case 16:
        case 91:
        case 17:
          break;
        default:
          return this.results_search();
      }
    }, AbstractChosen2.prototype.clipboard_event_checker = function() {
      var a2 = this;
      return setTimeout(function() {
        return a2.results_search();
      }, 50);
    }, AbstractChosen2.prototype.container_width = function() {
      return null != this.options.width ? this.options.width : "" + this.form_field.offsetWidth + "px";
    }, AbstractChosen2.prototype.include_option_in_results = function(a2) {
      return this.is_multiple && !this.display_selected_options && a2.selected ? false : !this.display_disabled_options && a2.disabled ? false : a2.empty ? false : true;
    }, AbstractChosen2.prototype.search_results_touchstart = function(a2) {
      return this.touch_started = true, this.search_results_mouseover(a2);
    }, AbstractChosen2.prototype.search_results_touchmove = function(a2) {
      return this.touch_started = false, this.search_results_mouseout(a2);
    }, AbstractChosen2.prototype.search_results_touchend = function(a2) {
      return this.touch_started ? this.search_results_mouseup(a2) : void 0;
    }, AbstractChosen2.prototype.outerHTML = function(a2) {
      var b2;
      return a2.outerHTML ? a2.outerHTML : (b2 = document.createElement("div"), b2.appendChild(a2), b2.innerHTML);
    }, AbstractChosen2.browser_is_supported = function() {
      return "Microsoft Internet Explorer" === window.navigator.appName ? document.documentMode >= 8 : /iP(od|hone)/i.test(window.navigator.userAgent) ? false : /Android/i.test(window.navigator.userAgent) && /Mobile/i.test(window.navigator.userAgent) ? false : true;
    }, AbstractChosen2.default_multiple_text = "Select Some Options", AbstractChosen2.default_single_text = "Select an Option", AbstractChosen2.default_no_result_text = "No results match", AbstractChosen2;
  }(), a = jQuery, a.fn.extend({ chosen: function(b2) {
    return AbstractChosen.browser_is_supported() ? this.each(function() {
      var c2, d2;
      c2 = a(this), d2 = c2.data("chosen"), "destroy" === b2 && d2 instanceof Chosen ? d2.destroy() : d2 instanceof Chosen || c2.data("chosen", new Chosen(this, b2));
    }) : this;
  } }), Chosen = function(c2) {
    function Chosen2() {
      return b = Chosen2.__super__.constructor.apply(this, arguments);
    }
    return d(Chosen2, c2), Chosen2.prototype.setup = function() {
      return this.form_field_jq = a(this.form_field), this.current_selectedIndex = this.form_field.selectedIndex, this.is_rtl = this.form_field_jq.hasClass("chosen-rtl");
    }, Chosen2.prototype.set_up_html = function() {
      var b2, c3;
      return b2 = ["chosen-container"], b2.push("chosen-container-" + (this.is_multiple ? "multi" : "single")), this.inherit_select_classes && this.form_field.className && b2.push(this.form_field.className), this.is_rtl && b2.push("chosen-rtl"), c3 = { "class": b2.join(" "), style: "width: " + this.container_width() + ";", title: this.form_field.title }, this.form_field.id.length && (c3.id = this.form_field.id.replace(/[^\w]/g, "_") + "_chosen"), this.container = a("<div />", c3), this.is_multiple ? this.container.html('<ul class="chosen-choices"><li class="search-field"><input type="text" value="' + this.default_text + '" class="default" autocomplete="off" style="width:25px;" /></li></ul><div class="chosen-drop"><ul class="chosen-results"></ul></div>') : this.container.html('<a class="chosen-single chosen-default" tabindex="-1"><span>' + this.default_text + '</span><div><b></b></div></a><div class="chosen-drop"><div class="chosen-search"><input type="text" autocomplete="off" /></div><ul class="chosen-results"></ul></div>'), this.form_field_jq.hide().after(this.container), this.dropdown = this.container.find("div.chosen-drop").first(), this.search_field = this.container.find("input").first(), this.search_results = this.container.find("ul.chosen-results").first(), this.search_field_scale(), this.search_no_results = this.container.find("li.no-results").first(), this.is_multiple ? (this.search_choices = this.container.find("ul.chosen-choices").first(), this.search_container = this.container.find("li.search-field").first()) : (this.search_container = this.container.find("div.chosen-search").first(), this.selected_item = this.container.find(".chosen-single").first()), this.results_build(), this.set_tab_index(), this.set_label_behavior(), this.form_field_jq.trigger("chosen:ready", { chosen: this });
    }, Chosen2.prototype.register_observers = function() {
      var a2 = this;
      return this.container.bind("touchstart.chosen", function(b2) {
        a2.container_mousedown(b2);
      }), this.container.bind("touchend.chosen", function(b2) {
        a2.container_mouseup(b2);
      }), this.container.bind("mousedown.chosen", function(b2) {
        a2.container_mousedown(b2);
      }), this.container.bind("mouseup.chosen", function(b2) {
        a2.container_mouseup(b2);
      }), this.container.bind("mouseenter.chosen", function(b2) {
        a2.mouse_enter(b2);
      }), this.container.bind("mouseleave.chosen", function(b2) {
        a2.mouse_leave(b2);
      }), this.search_results.bind("mouseup.chosen", function(b2) {
        a2.search_results_mouseup(b2);
      }), this.search_results.bind("mouseover.chosen", function(b2) {
        a2.search_results_mouseover(b2);
      }), this.search_results.bind("mouseout.chosen", function(b2) {
        a2.search_results_mouseout(b2);
      }), this.search_results.bind("mousewheel.chosen DOMMouseScroll.chosen", function(b2) {
        a2.search_results_mousewheel(b2);
      }), this.search_results.bind("touchstart.chosen", function(b2) {
        a2.search_results_touchstart(b2);
      }), this.search_results.bind("touchmove.chosen", function(b2) {
        a2.search_results_touchmove(b2);
      }), this.search_results.bind("touchend.chosen", function(b2) {
        a2.search_results_touchend(b2);
      }), this.form_field_jq.bind("chosen:updated.chosen", function(b2) {
        a2.results_update_field(b2);
      }), this.form_field_jq.bind("chosen:activate.chosen", function(b2) {
        a2.activate_field(b2);
      }), this.form_field_jq.bind("chosen:open.chosen", function(b2) {
        a2.container_mousedown(b2);
      }), this.form_field_jq.bind("chosen:close.chosen", function(b2) {
        a2.input_blur(b2);
      }), this.search_field.bind("blur.chosen", function(b2) {
        a2.input_blur(b2);
      }), this.search_field.bind("keyup.chosen", function(b2) {
        a2.keyup_checker(b2);
      }), this.search_field.bind("keydown.chosen", function(b2) {
        a2.keydown_checker(b2);
      }), this.search_field.bind("focus.chosen", function(b2) {
        a2.input_focus(b2);
      }), this.search_field.bind("cut.chosen", function(b2) {
        a2.clipboard_event_checker(b2);
      }), this.search_field.bind("paste.chosen", function(b2) {
        a2.clipboard_event_checker(b2);
      }), this.is_multiple ? this.search_choices.bind("click.chosen", function(b2) {
        a2.choices_click(b2);
      }) : this.container.bind("click.chosen", function(a3) {
        a3.preventDefault();
      });
    }, Chosen2.prototype.destroy = function() {
      return a(this.container[0].ownerDocument).unbind("click.chosen", this.click_test_action), this.search_field[0].tabIndex && (this.form_field_jq[0].tabIndex = this.search_field[0].tabIndex), this.container.remove(), this.form_field_jq.removeData("chosen"), this.form_field_jq.show();
    }, Chosen2.prototype.search_field_disabled = function() {
      return this.is_disabled = this.form_field_jq[0].disabled, this.is_disabled ? (this.container.addClass("chosen-disabled"), this.search_field[0].disabled = true, this.is_multiple || this.selected_item.unbind("focus.chosen", this.activate_action), this.close_field()) : (this.container.removeClass("chosen-disabled"), this.search_field[0].disabled = false, this.is_multiple ? void 0 : this.selected_item.bind("focus.chosen", this.activate_action));
    }, Chosen2.prototype.container_mousedown = function(b2) {
      return this.is_disabled || (b2 && "mousedown" === b2.type && !this.results_showing && b2.preventDefault(), null != b2 && a(b2.target).hasClass("search-choice-close")) ? void 0 : (this.active_field ? this.is_multiple || !b2 || a(b2.target)[0] !== this.selected_item[0] && !a(b2.target).parents("a.chosen-single").length || (b2.preventDefault(), this.results_toggle()) : (this.is_multiple && this.search_field.val(""), a(this.container[0].ownerDocument).bind("click.chosen", this.click_test_action), this.results_show()), this.activate_field());
    }, Chosen2.prototype.container_mouseup = function(a2) {
      return "ABBR" !== a2.target.nodeName || this.is_disabled ? void 0 : this.results_reset(a2);
    }, Chosen2.prototype.search_results_mousewheel = function(a2) {
      var b2;
      return a2.originalEvent && (b2 = a2.originalEvent.deltaY || -a2.originalEvent.wheelDelta || a2.originalEvent.detail), null != b2 ? (a2.preventDefault(), "DOMMouseScroll" === a2.type && (b2 = 40 * b2), this.search_results.scrollTop(b2 + this.search_results.scrollTop())) : void 0;
    }, Chosen2.prototype.blur_test = function() {
      return !this.active_field && this.container.hasClass("chosen-container-active") ? this.close_field() : void 0;
    }, Chosen2.prototype.close_field = function() {
      return a(this.container[0].ownerDocument).unbind("click.chosen", this.click_test_action), this.active_field = false, this.results_hide(), this.container.removeClass("chosen-container-active"), this.clear_backstroke(), this.show_search_field_default(), this.search_field_scale();
    }, Chosen2.prototype.activate_field = function() {
      return this.container.addClass("chosen-container-active"), this.active_field = true, this.search_field.val(this.search_field.val()), this.search_field.focus();
    }, Chosen2.prototype.test_active_click = function(b2) {
      var c3;
      return c3 = a(b2.target).closest(".chosen-container"), c3.length && this.container[0] === c3[0] ? this.active_field = true : this.close_field();
    }, Chosen2.prototype.results_build = function() {
      return this.parsing = true, this.selected_option_count = null, this.results_data = SelectParser.select_to_array(this.form_field), this.is_multiple ? this.search_choices.find("li.search-choice").remove() : this.is_multiple || (this.single_set_selected_text(), this.disable_search || this.form_field.options.length <= this.disable_search_threshold ? (this.search_field[0].readOnly = true, this.container.addClass("chosen-container-single-nosearch")) : (this.search_field[0].readOnly = false, this.container.removeClass("chosen-container-single-nosearch"))), this.update_results_content(this.results_option_build({ first: true })), this.search_field_disabled(), this.show_search_field_default(), this.search_field_scale(), this.parsing = false;
    }, Chosen2.prototype.result_do_highlight = function(a2) {
      var b2, c3, d2, e, f;
      if (a2.length) {
        if (this.result_clear_highlight(), this.result_highlight = a2, this.result_highlight.addClass("highlighted"), d2 = parseInt(this.search_results.css("maxHeight"), 10), f = this.search_results.scrollTop(), e = d2 + f, c3 = this.result_highlight.position().top + this.search_results.scrollTop(), b2 = c3 + this.result_highlight.outerHeight(), b2 >= e) return this.search_results.scrollTop(b2 - d2 > 0 ? b2 - d2 : 0);
        if (f > c3) return this.search_results.scrollTop(c3);
      }
    }, Chosen2.prototype.result_clear_highlight = function() {
      return this.result_highlight && this.result_highlight.removeClass("highlighted"), this.result_highlight = null;
    }, Chosen2.prototype.results_show = function() {
      return this.is_multiple && this.max_selected_options <= this.choices_count() ? (this.form_field_jq.trigger("chosen:maxselected", { chosen: this }), false) : (this.container.addClass("chosen-with-drop"), this.results_showing = true, this.search_field.focus(), this.search_field.val(this.search_field.val()), this.winnow_results(), this.form_field_jq.trigger("chosen:showing_dropdown", { chosen: this }));
    }, Chosen2.prototype.update_results_content = function(a2) {
      return this.search_results.html(a2);
    }, Chosen2.prototype.results_hide = function() {
      return this.results_showing && (this.result_clear_highlight(), this.container.removeClass("chosen-with-drop"), this.form_field_jq.trigger("chosen:hiding_dropdown", { chosen: this })), this.results_showing = false;
    }, Chosen2.prototype.set_tab_index = function() {
      var a2;
      return this.form_field.tabIndex ? (a2 = this.form_field.tabIndex, this.form_field.tabIndex = -1, this.search_field[0].tabIndex = a2) : void 0;
    }, Chosen2.prototype.set_label_behavior = function() {
      var b2 = this;
      return this.form_field_label = this.form_field_jq.parents("label"), !this.form_field_label.length && this.form_field.id.length && (this.form_field_label = a("label[for='" + this.form_field.id + "']")), this.form_field_label.length > 0 ? this.form_field_label.bind("click.chosen", function(a2) {
        return b2.is_multiple ? b2.container_mousedown(a2) : b2.activate_field();
      }) : void 0;
    }, Chosen2.prototype.show_search_field_default = function() {
      return this.is_multiple && this.choices_count() < 1 && !this.active_field ? (this.search_field.val(this.default_text), this.search_field.addClass("default")) : (this.search_field.val(""), this.search_field.removeClass("default"));
    }, Chosen2.prototype.search_results_mouseup = function(b2) {
      var c3;
      return c3 = a(b2.target).hasClass("active-result") ? a(b2.target) : a(b2.target).parents(".active-result").first(), c3.length ? (this.result_highlight = c3, this.result_select(b2), this.search_field.focus()) : void 0;
    }, Chosen2.prototype.search_results_mouseover = function(b2) {
      var c3;
      return c3 = a(b2.target).hasClass("active-result") ? a(b2.target) : a(b2.target).parents(".active-result").first(), c3 ? this.result_do_highlight(c3) : void 0;
    }, Chosen2.prototype.search_results_mouseout = function(b2) {
      return a(b2.target).hasClass("active-result") ? this.result_clear_highlight() : void 0;
    }, Chosen2.prototype.choice_build = function(b2) {
      var c3, d2, e = this;
      return c3 = a("<li />", { "class": "search-choice" }).html("<span>" + b2.html + "</span>"), b2.disabled ? c3.addClass("search-choice-disabled") : (d2 = a("<a />", { "class": "search-choice-close", "data-option-array-index": b2.array_index }), d2.bind("click.chosen", function(a2) {
        return e.choice_destroy_link_click(a2);
      }), c3.append(d2)), this.search_container.before(c3);
    }, Chosen2.prototype.choice_destroy_link_click = function(b2) {
      return b2.preventDefault(), b2.stopPropagation(), this.is_disabled ? void 0 : this.choice_destroy(a(b2.target));
    }, Chosen2.prototype.choice_destroy = function(a2) {
      return this.result_deselect(a2[0].getAttribute("data-option-array-index")) ? (this.show_search_field_default(), this.is_multiple && this.choices_count() > 0 && this.search_field.val().length < 1 && this.results_hide(), a2.parents("li").first().remove(), this.search_field_scale()) : void 0;
    }, Chosen2.prototype.results_reset = function() {
      return this.reset_single_select_options(), this.form_field.options[0].selected = true, this.single_set_selected_text(), this.show_search_field_default(), this.results_reset_cleanup(), this.form_field_jq.trigger("change"), this.active_field ? this.results_hide() : void 0;
    }, Chosen2.prototype.results_reset_cleanup = function() {
      return this.current_selectedIndex = this.form_field.selectedIndex, this.selected_item.find("abbr").remove();
    }, Chosen2.prototype.result_select = function(a2) {
      var b2, c3;
      return this.result_highlight ? (b2 = this.result_highlight, this.result_clear_highlight(), this.is_multiple && this.max_selected_options <= this.choices_count() ? (this.form_field_jq.trigger("chosen:maxselected", { chosen: this }), false) : (this.is_multiple ? b2.removeClass("active-result") : this.reset_single_select_options(), c3 = this.results_data[b2[0].getAttribute("data-option-array-index")], c3.selected = true, this.form_field.options[c3.options_index].selected = true, this.selected_option_count = null, this.is_multiple ? this.choice_build(c3) : this.single_set_selected_text(c3.text), (a2.metaKey || a2.ctrlKey) && this.is_multiple || this.results_hide(), this.search_field.val(""), (this.is_multiple || this.form_field.selectedIndex !== this.current_selectedIndex) && this.form_field_jq.trigger("change", { selected: this.form_field.options[c3.options_index].value }), this.current_selectedIndex = this.form_field.selectedIndex, this.search_field_scale())) : void 0;
    }, Chosen2.prototype.single_set_selected_text = function(a2) {
      return null == a2 && (a2 = this.default_text), a2 === this.default_text ? this.selected_item.addClass("chosen-default") : (this.single_deselect_control_build(), this.selected_item.removeClass("chosen-default")), this.selected_item.find("span").text(a2);
    }, Chosen2.prototype.result_deselect = function(a2) {
      var b2;
      return b2 = this.results_data[a2], this.form_field.options[b2.options_index].disabled ? false : (b2.selected = false, this.form_field.options[b2.options_index].selected = false, this.selected_option_count = null, this.result_clear_highlight(), this.results_showing && this.winnow_results(), this.form_field_jq.trigger("change", { deselected: this.form_field.options[b2.options_index].value }), this.search_field_scale(), true);
    }, Chosen2.prototype.single_deselect_control_build = function() {
      return this.allow_single_deselect ? (this.selected_item.find("abbr").length || this.selected_item.find("span").first().after('<abbr class="search-choice-close"></abbr>'), this.selected_item.addClass("chosen-single-with-deselect")) : void 0;
    }, Chosen2.prototype.get_search_text = function() {
      return this.search_field.val() === this.default_text ? "" : a("<div/>").text(a.trim(this.search_field.val())).html();
    }, Chosen2.prototype.winnow_results_set_highlight = function() {
      var a2, b2;
      return b2 = this.is_multiple ? [] : this.search_results.find(".result-selected.active-result"), a2 = b2.length ? b2.first() : this.search_results.find(".active-result").first(), null != a2 ? this.result_do_highlight(a2) : void 0;
    }, Chosen2.prototype.no_results = function(b2) {
      var c3;
      return c3 = a('<li class="no-results">' + this.results_none_found + ' "<span></span>"</li>'), c3.find("span").first().html(b2), this.search_results.append(c3), this.form_field_jq.trigger("chosen:no_results", { chosen: this });
    }, Chosen2.prototype.no_results_clear = function() {
      return this.search_results.find(".no-results").remove();
    }, Chosen2.prototype.keydown_arrow = function() {
      var a2;
      return this.results_showing && this.result_highlight ? (a2 = this.result_highlight.nextAll("li.active-result").first()) ? this.result_do_highlight(a2) : void 0 : this.results_show();
    }, Chosen2.prototype.keyup_arrow = function() {
      var a2;
      return this.results_showing || this.is_multiple ? this.result_highlight ? (a2 = this.result_highlight.prevAll("li.active-result"), a2.length ? this.result_do_highlight(a2.first()) : (this.choices_count() > 0 && this.results_hide(), this.result_clear_highlight())) : void 0 : this.results_show();
    }, Chosen2.prototype.keydown_backstroke = function() {
      var a2;
      return this.pending_backstroke ? (this.choice_destroy(this.pending_backstroke.find("a").first()), this.clear_backstroke()) : (a2 = this.search_container.siblings("li.search-choice").last(), a2.length && !a2.hasClass("search-choice-disabled") ? (this.pending_backstroke = a2, this.single_backstroke_delete ? this.keydown_backstroke() : this.pending_backstroke.addClass("search-choice-focus")) : void 0);
    }, Chosen2.prototype.clear_backstroke = function() {
      return this.pending_backstroke && this.pending_backstroke.removeClass("search-choice-focus"), this.pending_backstroke = null;
    }, Chosen2.prototype.keydown_checker = function(a2) {
      var b2, c3;
      switch (b2 = null != (c3 = a2.which) ? c3 : a2.keyCode, this.search_field_scale(), 8 !== b2 && this.pending_backstroke && this.clear_backstroke(), b2) {
        case 8:
          this.backstroke_length = this.search_field.val().length;
          break;
        case 9:
          this.results_showing && !this.is_multiple && this.result_select(a2), this.mouse_on_container = false;
          break;
        case 13:
          this.results_showing && a2.preventDefault();
          break;
        case 32:
          this.disable_search && a2.preventDefault();
          break;
        case 38:
          a2.preventDefault(), this.keyup_arrow();
          break;
        case 40:
          a2.preventDefault(), this.keydown_arrow();
      }
    }, Chosen2.prototype.search_field_scale = function() {
      var b2, c3, d2, e, f, g, h, i, j;
      if (this.is_multiple) {
        for (d2 = 0, h = 0, f = "position:absolute; left: -1000px; top: -1000px; display:none;", g = ["font-size", "font-style", "font-weight", "font-family", "line-height", "text-transform", "letter-spacing"], i = 0, j = g.length; j > i; i++) e = g[i], f += e + ":" + this.search_field.css(e) + ";";
        return b2 = a("<div />", { style: f }), b2.text(this.search_field.val()), a("body").append(b2), h = b2.width() + 25, b2.remove(), c3 = this.container.outerWidth(), h > c3 - 10 && (h = c3 - 10), this.search_field.css({ width: h + "px" });
      }
    }, Chosen2;
  }(AbstractChosen);
}.call(this);
