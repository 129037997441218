(function(a) {
  a.fn.autoResize = function(j) {
    var b = a.extend({ onResize: function() {
    }, animate: true, animateDuration: 150, animateCallback: function() {
    }, extraSpace: 20, limit: 1e3 }, j);
    this.filter("textarea").each(function() {
      var c = a(this).css({ resize: "none", "overflow-y": "hidden" }), k = c.height(), f = function() {
        var l = ["height", "width", "lineHeight", "textDecoration", "letterSpacing"], h = {};
        a.each(l, function(d, e) {
          h[e] = c.css(e);
        });
        return c.clone().removeAttr("id").removeAttr("name").css({ position: "absolute", top: 0, left: -9999 }).css(h).attr("tabIndex", "-1").insertBefore(c);
      }(), i = null, g = function() {
        f.height(0).val(a(this).val()).scrollTop(1e4);
        var d = Math.max(f.scrollTop(), k) + b.extraSpace, e = a(this).add(f);
        if (i === d) {
          return;
        }
        i = d;
        if (d >= b.limit) {
          a(this).css("overflow-y", "");
          return;
        }
        b.onResize.call(this);
        b.animate && c.css("display") === "block" ? e.stop().animate({ height: d }, b.animateDuration, b.animateCallback) : e.height(d);
      };
      c.unbind(".dynSiz").bind("keyup.dynSiz", g).bind("keydown.dynSiz", g).bind("change.dynSiz", g);
    });
    return this;
  };
})(jQuery);
