(function($) {
  function arrayToHash(array) {
    var hash = {};
    $.each(array, function() {
      hash[this] = this;
    });
    return hash;
  }
  function regexpEscape(exp) {
    return exp.replace(/(\/|\.|\*|\+|\?|\||\(|\)|\[|\]|\{|\}|\\)/g, "\\$1");
  }
  function highlight(text, phrase, highlighter) {
    var re = new RegExp("(" + regexpEscape(phrase) + `)(?!(?:[^<]*?)(?:["'])[^<>]*>)`, "i");
    return text.replace(re, highlighter);
  }
  $.widget("ui.combobox", {
    options: {
      animated: "blind",
      ajaxOptions: { dataType: "json" },
      autoFilter: true,
      cache: false,
      control: true,
      delay: 1,
      event: "focus",
      height: "outerHeight",
      data: {},
      escapeHtml: true,
      overrideLocalData: false,
      paramName: null,
      select: function(event, ui) {
        $(this).val(ui.value);
      },
      textHighlighter: "<strong>$1</strong>",
      width: "outerWidth"
    },
    cache: {},
    _init: function() {
      var self2 = this;
      this.element.wrap('<div class="ui-widget ui-combobox ui-state-default" tabindex="0"></div>');
      this.container = this.element.parent();
      this.container.bind(this.options.event + ".combobox", function() {
        self2._focus();
      }).bind("blur", function() {
        self2._blur();
      }).bind("keydown", function(e) {
        self2._keydown(e);
      }).bind("keyup", function(e) {
        self2._keyup(e);
      });
      if (this.element.is("[disabled]")) {
        this.container.addClass("ui-state-disabled");
      }
      if (this.options.control) {
        this.control = $(
          '<div class="ui-combobox-control"><span class="ui-icon ui-icon-triangle-1-s" /></div>'
        ).click(function() {
          if (!self2.options.disabled) self2.toggle();
        }).appendTo(this.container);
      }
      this.dataElement = $(
        '<div class="ui-combobox-list ui-widget-content ui-corner-bottom"></div>'
      ).css("position", "absolute").hide().appendTo(this.container);
      if (this.element[0].nodeName == "SELECT" && !this.options.overrideLocalData) {
        var data = {};
        $.each(this.element[0].options, function() {
          data[this.value] = this.text;
        });
        this.options.data = data;
        var attributes = {};
        $.each(this.element[0].attributes, function() {
          attributes[this.name] = this.value;
        });
        var input = $("<input />").attr(attributes).attr("type", "text");
        this.element.replaceWith(input);
        this.element = input;
        this.wasSelect = true;
      }
      this.collapsed = true;
      this.selectedIndex = 0;
      this.highlightedIndex = -1;
      this.refresh = false;
      this.element.attr("autocomplete", "off");
    },
    destroy: function() {
      this.container.before(this.element).remove();
      this.element.removeAttr("autocomplete");
    },
    expand: function() {
      if (!this.collapsed) return;
      this._refresh();
      var offset = this.element.position();
      offset.top = offset.top + this.element[this.options.height]();
      this.dataElement.css({ left: offset.left, top: offset.top, width: this.element[this.options.width]() }).show(this.options.animated);
      this.collapsed = false;
      if (this.options.control) {
        this.control.children(".ui-icon").removeClass("ui-icon-triangle-1-s").addClass("ui-icon-triangle-1-n");
      }
    },
    collapse: function() {
      if (this.collapsed) return;
      this.dataElement.hide(this.options.animated);
      this.collapsed = true;
      if (this.options.control) {
        this.control.children(".ui-icon").removeClass("ui-icon-triangle-1-n").addClass("ui-icon-triangle-1-s");
      }
    },
    toggle: function() {
      this[this.collapsed ? "expand" : "collapse"]();
    },
    enable: function() {
      this.element.removeAttr("disabled");
      this.container.removeClass("ui-state-disabled");
      $.widget.prototype.enable.apply(self, arguments);
    },
    disable: function() {
      this.element.attr("disabled", true);
      this.container.addClass("ui-state-disabled");
      this.collapse();
      $.widget.prototype.disable.apply(self, arguments);
    },
    deselect: function() {
      this.selectedIndex = -1;
    },
    _getData: function(key) {
      switch (key) {
        case "selectedIndex":
          return this.selectedIndex;
      }
      $.widget.prototype._getData.apply(self, arguments);
    },
    _setData: function(key, value) {
      switch (key) {
        case "data":
          this.options.data = value;
          if (!this.collapsed) {
            this._update();
          } else {
            this.refresh = true;
          }
          break;
        case "selectedIndex":
          this._select(value);
          break;
      }
      $.widget.prototype._setData.apply(self, arguments);
    },
    _refresh: function() {
      if (this.refresh) {
        this._update();
        this.refresh = false;
      }
    },
    _focus: function() {
      this.container.addClass("ui-state-focus");
      if (this.dataElement.children("ul").children("li").length) this.expand();
    },
    _blur: function() {
      this.container.removeClass("ui-state-focus");
      this.collapse();
    },
    _keydown: function(e) {
      if (!this.collapsed) {
        switch (e.keyCode) {
          case 9:
          case 13:
            return;
          case 27:
            this.collapse();
          case 37:
          case 39:
            return;
          case 38:
            return;
          case 40:
            return;
        }
      } else if (e.keyCode == 40) {
        this.expand();
      } else if (e.keyCode == 9 || e.keyCode == 13) {
        return;
      }
    },
    _keyup: function(e) {
      switch (e.keyCode) {
        case 9:
        case 13:
        case 27:
        case 37:
        case 39:
        case 38:
        case 40:
          return;
      }
      this._update();
    },
    _update: function() {
      var value = $.trim(this.element.val() || ""), data = this.options.data;
      console.log({ value });
      if ($.isFunction(data)) {
        data = data.call(this.element[0], value);
      } else if (typeof data == "string") {
        data = this._ajax(data);
      } else {
        if ($.isArray(data)) {
          data = arrayToHash(data);
        }
        if (this.options.autoFilter) {
          data = this._filter(data, value);
        }
      }
      if ($.isArray(data)) {
        data = arrayToHash(data);
      }
      this._populate(data);
    },
    _populate: function(data) {
      if ((data == null ? void 0 : data.length) == 0) {
        if (!this.collapsed) this.collapse();
        return;
      }
      var list = $("<ul></ul>"), value = this.element.val(), length = 0, self2 = this, div = $("<div></div>");
      $.each(data, function(itemValue, originalText) {
        var text = $.trim(originalText);
        if (self2.options.escapeHtml) {
          text = highlight(div.text(text).text(), value, self2.options.textHighlighter);
        }
        $("<li></li>").html(text).data("combobox:value", itemValue).data("combobox:text", originalText).mouseenter(function() {
          self2._highlight(self2._indexFor(this));
        }).click(function() {
          self2._select(self2._indexFor(this));
          if (self2.options.click !== void 0) {
            var url = $(this).children().attr("data-url");
            self2.options.click(url);
          }
        }).appendTo(list);
      });
      this.dataElement.html("").append(list);
    },
    _ajax: function(url) {
      var _a, _b, _c;
      var self2 = this;
      var value = $.trim((_a = this == null ? void 0 : this.element) == null ? void 0 : _a.val());
      if (this.xhr) this.xhr.abort();
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = void 0;
      }
      if (!this.options.cache || self2.cache[value] === void 0) {
        let fire2 = function() {
          self2.interval = void 0;
          self2.xhr = $.ajax(options);
        };
        var fire = fire2;
        var options = $.extend(this.options.ajaxOptions, { url }), self2 = this;
        options.data = options.data || {};
        options.data[this.options.paramName || this.element[0].name] = value;
        options.beforeSend = function(xhr) {
          self2.container.addClass("ui-state-loading");
          self2._trigger("ajaxbeforesend", null, { xhr });
        };
        options.complete = function(xhr, textStatus) {
          self2.container.removeClass("ui-state-loading");
          self2._trigger("ajaxcomplete", null, { xhr, textStatus });
        };
        options.error = function(xhr, textStatus, errorThrown) {
          self2._trigger("ajaxerror", null, {
            xhr,
            textStatus,
            errorThrown
          });
        };
        options.success = function(data, textStatus, xhr) {
          if (self2.xhr == xhr) self2.xhr = void 0;
          self2._trigger("ajaxsuccess", null, { xhr, data, textStatus });
          if ($.isArray(data)) {
            data = arrayToHash(data);
          }
          self2.cache[value] = data;
          self2._populate(data);
          self2.expand();
        };
        if (this.options.delay > 0) {
          this.interval = setTimeout(fire2, this.options.delay * 1e3);
        } else {
          fire2();
        }
        return {};
      } else {
        return (_c = (_b = $.ui) == null ? void 0 : _b.combobox) == null ? void 0 : _c.cache[value];
      }
    },
    _select: function(index) {
      if (index == -1) this.deselect();
      var item = this.dataElement.find("ul > li").eq(index);
      if (this.element.val() == "") return;
      var text = item.data("combobox:text"), value = item.data("combobox:value");
      this.selectedIndex = index;
      this._trigger("select", null, { index: this._indexFor(item[0]), value, text });
      this.collapse();
    },
    _highlight: function(index) {
      var query = this.dataElement.find("ul > li");
      query.eq(this.highlightedIndex).removeClass("ui-state-highlight");
      if (index >= 0) {
        query.eq(index).addClass("ui-state-highlight");
      }
      this.highlightedIndex = index;
    },
    _prev: function() {
      var index = this.highlightedIndex - 1;
      if (index < 0) {
        index = -1;
      }
      this._highlight(index);
    },
    _next: function() {
      var index = this.highlightedIndex + 1;
      if (index >= this._length) {
        index = this._length - 1;
      }
      this._highlight(index);
    },
    _filter: function(data, value) {
      var startsWith = new RegExp("^" + value, "i"), newList = {};
      $.each(data, function(key, text) {
        if (text.match(startsWith)) {
          newList[key] = text;
        }
      });
      return newList;
    },
    _indexFor: function(item) {
      return this.dataElement.find("ul > li").index(item);
    }
  });
})(jQuery);
