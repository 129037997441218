import {} from "jquery-ujs";
import "ui.combobox";
import "jquery.minicolors";
import "chosen.jquery.min";
import "jquery.autoresize";
import "./videojs/video";
import "./video_interview_player";
import "./csv-data-lookup";
import "isomorphic-fetch/fetch-npm-node";
import Cookies from "js-cookie";
$.candidatelink = {
  helpers: {}
};
$.fn.indicateChange = function(element, options) {
  options = $.extend(
    {
      updateClass: "changes-pending"
    },
    options || {}
  );
  function setClass() {
    $(element).addClass(options.updateClass).attr("title", function(i, title) {
      return title === void 0 ? "There are changes awaiting to be saved" : title;
    });
  }
  this.find('input[type="text"], input[type="password"], select, textarea').change(setClass).end().find('input[type="radio"], input[type="checkbox"]').click(setClass).end().find(".ui-sortable").bind("sortchange", setClass).end().find(".ui-slider").bind("slidechange", setClass);
};
$.candidatelink.helpers.sortableFix = function(e, ui) {
  ui.children().each(function() {
    $(this).width($(this).width());
  });
  return ui;
};
$.candidatelink.helpers.tabsLoad = function(e, u) {
  var ui;
  if (u === void 0) {
    ui = $.candidatelink.currentTabUi;
    if (ui === void 0) return false;
  } else {
    ui = $.candidatelink.currentTabUi = u;
  }
  var tabs = $(this).data("tabs");
  $("a", ui.panel).not('[href="#"]').not(".chosen-single").unbind("click").click(function() {
    var url = this.href, data = "", method = "get";
    if ($(this).is("[data-confirm]") && !confirm($(this).attr("data-confirm"))) {
      return false;
    }
    if ($(this).is("[data-popup]")) {
      alert("popup");
      return false;
    }
    if ($(this).is("[data-url]")) {
      url = $(this).attr("data-url");
    }
    if ($(this).is("[data-method]")) {
      method = $(this).attr("data-method");
    }
    if ($(this).is("[data-with]")) {
      data = $(this).attr("data-with");
    }
    $.ajax({
      url,
      type: method,
      data,
      success: function(data2, textStatus) {
        $(ui.panel).html(data2);
        $(ui.panel).find("form").on("submit", function() {
          $(window).off("beforeunload");
          return true;
        });
        if (tabs != null) tabs._trigger("load", null, ui);
      }
    });
    return false;
  });
  $("form", ui.panel).not("[data-remote]").unbind("submit").submit(function() {
    if ($(this).prop("action") && $(this).prop("action").length > 1) {
      $(window).off("beforeunload");
      return true;
    }
    var data = {};
    $.each($(this).serializeArray(), function() {
      data[this.name] = this.value;
    });
    $(ui.panel).on("load", this.action, data, function() {
      if (tabs != null) tabs._trigger("load", null, ui);
    });
    return false;
  });
};
$.candidatelink.formDatePicker = function(selector, options) {
  $(document).ready(function() {
    const dateFormat = "dd/mm/yy";
    var DAY = '[name$="(3i)]"]', MONTH = '[name$="(2i)]"]', YEAR = '[name$="(1i)]"]';
    var query = jQuery(selector), date = new Date(
      query.find(YEAR).val(),
      parseInt(query.find(MONTH).val()) - 1,
      query.find(DAY).val()
    ), dateText = isValidDate(date) ? jQuery.datepicker.formatDate(dateFormat, date) : "", textfield = jQuery(
      '<input type="text" readonly="readonly" value="' + dateText + '" />'
    ), parent = null;
    query.find([DAY, MONTH, YEAR].join(", ")).each(function() {
      query.append(
        '<input name="' + jQuery(this).attr("name") + '" type="hidden" value="' + jQuery(this).val() + '" />'
      );
      parent = jQuery(this).parent().parent();
      jQuery(this).parent().remove();
    });
    parent.prepend(textfield);
    textfield.wrap("<li></li>").parent().prepend("<label>Date</label>").end().datepicker(Object.assign(options || {}, {
      changeMonth: true,
      changeYear: true,
      dateFormat,
      defaultDate: isValidDate(date) ? date : null,
      onSelect: function(dateText2, inst) {
        query.find(DAY).val(inst.currentDay);
        query.find(MONTH).val(inst.currentMonth + 1);
        query.find(YEAR).val(inst.currentYear);
      }
    }));
  });
};
$.candidatelink.dialogForm = function(id, options, noAjax) {
  var buttons2 = { submit: "Submit", cancel: "Cancel" };
  options = $.extend(
    {
      autoOpen: false,
      modal: false,
      buttons: [
        {
          text: buttons2.cancel,
          click: function() {
            $(this).dialog("close");
          }
        },
        {
          text: buttons2.submit,
          click: function() {
            var dialog = $(this), form = $(this).find("form").eq(0);
            if (dialog.data("noAjax") !== true) {
              $.ajax({
                url: form.attr("action") || document.location,
                data: form.serialize(),
                dataType: "html",
                type: form.attr("method") || "post",
                success: function(data, textStatus, xhr) {
                  $("body").append(data);
                  dialog.dialog("close");
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                  if (XMLHttpRequest.status == 500) {
                    dialog.html(XMLHttpRequest.responseText);
                  }
                }
              });
            } else {
              form.submit();
              dialog.dialog("close");
            }
          }
        }
      ],
      close: function() {
        $(this).find("form").each(function() {
          this.reset();
        });
      }
    },
    options || {}
  );
  $(document).ready(function() {
    $("#" + id).hide().find("form fieldset.actions").hide().end().data("noAjax", noAjax || false).dialog(options);
  });
};
$.candidatelink.dialogCloseOnly = function(id, options, noAjax) {
  const buttons2 = { cancel: "Close" };
  options = $.extend(
    {
      autoOpen: false,
      modal: false,
      buttons: [
        {
          text: buttons2.cancel,
          click: function() {
            $(this).dialog("close");
          }
        }
      ],
      close: function() {
        $(this).find("form").each(function() {
          this.reset();
        });
      }
    },
    options || {}
  );
  $(document).ready(function() {
    $("#" + id).hide().find("form fieldset.actions").hide().end().data("noAjax", noAjax || false).dialog(options);
  });
};
$.candidatelink.dialogFormWithoutSubmit = function(id, options) {
  options = $.extend(
    {
      autoOpen: false,
      modal: false,
      buttons: [
        {
          text: "Cancel",
          click: function() {
            $(this).dialog("close");
          }
        },
        {
          text: "Submit",
          click: function() {
            var dialog = $(this), form = $(this).find("form").eq(0);
            if (dialog.data("noAjax") !== true) {
              $.ajax({
                url: form.attr("action") || document.location,
                data: form.serialize(),
                dataType: "html",
                type: form.attr("method") || "post",
                success: function(data, textStatus, xhr) {
                  $("body").append(data);
                  dialog.dialog("close");
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                  if (XMLHttpRequest.status == 500) {
                    dialog.html(XMLHttpRequest.responseText);
                  }
                }
              });
            } else {
              form.submit();
              dialog.dialog("close");
            }
          }
        }
      ],
      close: function() {
        $(this).find("form").each(function() {
          this.reset();
        });
      }
    },
    options || {}
  );
  $(document).ready(function() {
    $("#" + id).hide().find("form fieldset.actions").hide().end().dialog(options);
  });
};
$.candidatelink.searchBox = function(selector, options) {
  $.extend(options, {
    click: function(url) {
      if (url !== void 0) {
        document.location = url;
      }
    }
  });
  $(selector).combobox(
    $.extend(
      {
        select: function(e, ui) {
          var url = null;
          try {
            url = $(ui.text).attr("data-url");
          } catch (e2) {
          }
          if (url) {
            document.location = url;
          } else {
            $(this).val(ui.value);
            $("#quick_search").submit();
          }
        },
        cache: false,
        control: false
      },
      options || {}
    )
  );
};
$.candidatelink.dialogHroForm = function(id, options, noAjax) {
  $(document).ready(function() {
    var buttons2 = saveCancelButtons(id);
    var $hroRecordId = $("#hro_record_id");
    if ($hroRecordId.val() !== "" && $hroRecordId.val() !== void 0) {
      buttons2 = $.extend(
        {
          Export: function() {
            $.ajax({
              type: "POST",
              url: window.location.pathname + "/hro_export",
              success: function() {
              }
            });
            $(this).dialog("close");
          }
        },
        buttons2
      );
    }
    if ($hroRecordId.val() === void 0) {
      buttons2 = {};
    }
    createDialogForm(id, options, buttons2);
  });
};
$.candidatelink.dialogScoutOnboardForm = function(id, options, noAjax) {
  $(document).ready(function() {
    dialogSaveCancelButtonsForm(
      id,
      options,
      noAjax,
      "#scout_onboard_record_id",
      "Submit"
    );
  });
};
$.candidatelink.dialogKeypayForm = function(id, options, noAjax) {
  $(document).ready(function() {
    dialogSaveCancelButtonsForm(id, options, noAjax, "#keypay_record_id");
  });
};
$.candidatelink.dialogCognologyForm = function(id, options, noAjax) {
  $(document).ready(function() {
    buttons = {};
    if ($("#cognology_record_id").val() !== void 0) {
      buttons = saveCancelButtons(id);
    }
    if ($("#cognology_record_view_export_link").length > 0) {
      buttons = Object.assign(
        {
          "View Export": function() {
            $("#cognology_record_view_export_link")[0].click();
          }
        },
        buttons
      );
    }
    createDialogForm(id, options, buttons);
  });
};
$.candidatelink.dialogAlayacareForm = function(id, options) {
  var buttons2 = { submit: "Submit", cancel: "Cancel" };
  options = $.extend(
    {
      autoOpen: false,
      modal: false,
      buttons: [
        {
          text: buttons2.cancel,
          click: function() {
            $(this).dialog("close");
          }
        },
        {
          text: buttons2.submit,
          click: function() {
            var dialog = $(this), form = $(this).find("form").eq(0);
            var firstname = form.find("#firstname").val();
            var lastname = form.find("#lastname").val();
            var email = form.find("#email").val();
            var mobile_phone = form.find("#mobile_phone").val();
            var postcode = form.find("#postcode").val();
            if (!firstname || !lastname) {
              alert("First Name and Last Name are required.");
              return;
            }
            if (mobile_phone.length > 10) {
              alert("Phone number should not be longer than 10 digits.");
              return;
            }
            if (!isValidEmail(email)) {
              alert("Please enter a valid email address.");
              return;
            }
            if (!/^\d{4}$/.test(postcode)) {
              alert("Please enter a valid 4 digits Postcode.");
              return;
            }
            form.submit();
            dialog.dialog("close");
          }
        }
      ],
      close: function() {
        $(this).find("form").each(function() {
          this.reset();
        });
      }
    },
    options || {}
  );
  $(document).ready(function() {
    $("#" + id).hide().find("form fieldset.actions").hide().end().dialog(options);
  });
};
var isValidEmail = function(email) {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};
$.candidatelink.dialogSonruForm = function(id, options, noAjax) {
  $(document).ready(function() {
    dialogSaveCancelButtonsForm(id, options, noAjax, "#sonru_record_id");
  });
};
var dialogSaveCancelButtonsForm = function(id, options, noAjax, checkerSelector, saveLabel) {
  var buttons2 = saveCancelButtons(id);
  if ($(checkerSelector).val() === void 0) {
    buttons2 = {};
  }
  createDialogForm(id, options, buttons2);
};
var createDialogForm = function(id, options, buttons2) {
  options = $.extend(
    {
      autoOpen: false,
      modal: false,
      buttons: buttons2,
      close: function() {
        $(this).find("form").each(function() {
          this.reset();
        });
      }
    },
    options || {}
  );
  $("#" + id).hide().dialog(options);
};
var saveCancelButtons = function(id) {
  var label = $("#" + id).find('input[type="submit"]').val() || "Save";
  var result = {};
  result["Cancel"] = function() {
    $(this).dialog("close");
  };
  result[label] = function() {
    $(this).find("form").eq(0).submit();
    $(this).dialog("close");
  };
  return result;
};
window.genericDatetimeHelper = function(selector, label) {
  var DAY = '[name$="day]"]', MONTH = '[name$="month]"]', YEAR = '[name$="year]"]';
  var query = jQuery(selector), date = new Date(
    query.find(YEAR).val(),
    parseInt(query.find(MONTH).val()) - 1,
    query.find(DAY).val()
  ), dateText = jQuery.datepicker.formatDate(
    jQuery.datepicker._defaults.dateFormat,
    date
  ), textfield = jQuery(
    '<input type="text" readonly="readonly" value="' + dateText + '" />'
  ), parent = null;
  query.find([DAY, MONTH, YEAR].join(", ")).each(function() {
    query.append(
      '<input name="' + jQuery(this).attr("name") + '" type="hidden" value="' + jQuery(this).val() + '" />'
    );
    parent = jQuery(this).parent();
    jQuery(this).remove();
  });
  parent.prepend(textfield);
  textfield.parent().prepend("<label>" + label + "</label>").end().datepicker({
    defaultDate: date,
    onSelect: function(dateText2, inst) {
      query.find(DAY).val(inst.currentDay);
      query.find(MONTH).val(inst.currentMonth + 1);
      query.find(YEAR).val(inst.currentYear);
    }
  });
};
var isValidDate = function(date) {
  if (Object.prototype.toString.call(date) !== "[object Date]") return false;
  return !isNaN(date.getTime());
};
$.candidatelink.updatePositions = function(selector) {
  var i = 0;
  $(selector + ' input[name$="[position]"]').each(function() {
    if (this.value == -1) {
      this.value = i++;
    } else {
      i++;
    }
  });
};
$.app_sortable = {
  parentElement: null,
  helpers: {}
};
$.app_sortable.helpers.restoreOrder = function() {
  var cookie = Cookies.get("application_sortable_order");
  if (!cookie) return;
  var ordered_items = cookie.split(",");
  for (var i = ordered_items.length - 1; i >= 0; i--) {
    if (i === Math.floor(ordered_items.length / 2))
      $.app_sortable.parentElement.prepend(
        $.app_sortable.parentElement.find(".navigation")
      );
    $.app_sortable.parentElement.prepend(
      $.app_sortable.parentElement.find(
        "[data-js-sortable='" + ordered_items[i] + "']"
      )
    );
  }
};
$.app_sortable.helpers.resetOrder = function() {
  Cookies.remove("application_sortable_order");
  Cookies.remove("application_sortable_order", { path: "/admin" });
  window.location.reload();
};
$.app_sortable.helpers.init = function(parentEl) {
  $.app_sortable.parentElement = parentEl;
  $.app_sortable.helpers.restoreOrder();
  $.app_sortable.parentElement.sortable({
    axis: "y",
    handle: ".sortable_handle",
    items: "> div",
    containment: "parent",
    tolerance: "pointer",
    helper: $.candidatelink.helpers.sortableFix,
    update: function(event, ui) {
      var cooked = [];
      $.app_sortable.parentElement.find("[data-js-sortable]").each(function(index, domEle) {
        cooked[index] = $(domEle).data("js-sortable");
      });
      Cookies.set("application_sortable_order", cooked, {
        expires: 365,
        path: "/admin"
      });
    }
  });
};
$.app_async = {
  flashTimeout: null,
  helpers: {}
};
$.app_async.helpers.showFlashMsg = function(flashMsgs) {
  window.clearTimeout($.app_async.flashTimeout);
  var container = $("#ajax-flash-msg");
  container.empty();
  $(flashMsgs).each(function(index, element) {
    container.append(element);
  });
  container.show();
  container.click(function() {
    $(this).fadeOut("fast");
    window.clearTimeout($.app_async.flashTimeout);
  });
  $.app_async.flashTimeout = window.setTimeout(function() {
    container.fadeOut("fast");
  }, 4e3);
};
$.app_async.helpers.updateStatus = function(newStatus) {
  var status = $("#candidate dd.status");
  $(status).each(function(_, element) {
    var container = $(element);
    container.empty();
    container.append(newStatus);
  });
};
$.app_async.helpers.updateRatings = function(newRatings) {
  var ratings = $("#candidate dd.rating");
  $(ratings).each(function(index, element) {
    var oldEl = $(element);
    var newEl = $(newRatings).find(
      'dd[data-rating-type="' + oldEl.data("rating-type") + '"]'
    );
    oldEl.replaceWith(newEl);
  });
};
$.app_async.helpers.updateActivities = function(newActivities) {
  var activities = $("#activities");
  activities.html(newActivities);
};
$.app_async.helpers.checkboxWithConfirm = function(name, checkbox, token, url, alwaysConfirm) {
  if (!checkbox.checked || alwaysConfirm) {
    var confirmed = confirm(
      "This action will be applied immediately. Continue?"
    );
    if (!confirmed) {
      checkbox.checked = !checkbox.checked;
      return;
    }
  }
  var data = {
    authenticity_token: encodeURIComponent(token)
  };
  data[name] = checkbox.checked ? 1 : 0;
  $.ajax({
    data,
    dataType: "script",
    type: "put",
    url
  }).fail(function(req, status, error) {
    checkbox.checked = !checkbox.checked;
    alert("Operation failed: " + req.responseText);
  });
};
$.app_async.helpers.radiosWithConfirm = function(name, radio, token, url) {
  var confirmed = confirm("This action will be applied immediately. Continue?");
  if (!confirmed) {
    radio.checked = false;
    return;
  }
  var data = {
    authenticity_token: encodeURIComponent(token)
  };
  data[name] = radio.value;
  $.ajax({
    data,
    dataType: "script",
    type: "post",
    url
  }).fail(function(req, status, error) {
    radio.checked = false;
    alert("Operation failed: " + req.responseText);
  });
};
$(document).ajaxStart(function() {
  $("body").addClass("wait");
}).ajaxComplete(function() {
  $("body").removeClass("wait");
});
